import {
  CLIENT_ID,
  TENANT_NAME,
  SCOPE_ID,
  TENANT_DOMAIN_NAME,
  APP_ENV,
} from "./constants";

export const b2cPolicies = {
  names: {
    signIn: "B2C_1_signin",
  },
  authorities: {
    signIn: {
      authority: `https://${TENANT_DOMAIN_NAME}/${TENANT_NAME}.onmicrosoft.com/B2C_1_signin`,
    },
  },
  authorityDomain: TENANT_DOMAIN_NAME,
};

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};
const scopeUrlConstruct = () => {
  if (APP_ENV === "production") {
    return `https://${TENANT_DOMAIN_NAME}/${SCOPE_ID}/user.read`;
  }
  return `https://${TENANT_NAME}.onmicrosoft.com/${SCOPE_ID}/user.read`;
};

export const loginRequest = {
  scopes: [scopeUrlConstruct()],
};
