export const CLIENT_ID: string = process.env.REACT_APP_API_CLIENT_ID || "";
export const TENANT_NAME: string = process.env.REACT_APP_TENANT_NAME || "";
export const TENANT_DOMAIN_NAME: string =
  process.env.REACT_APP_TENANT_DOMAIN_NAME || "";
export const API_PATH: string = process.env.REACT_APP_PORTAL_API_PATH || "";
export const SCOPE_ID: string = process.env.REACT_APP_SCOPE_ID || "";
export const ADMIN_PATH: string = process.env.REACT_APP_ADMIN_PATH || "";
export const WIDGET_PATH: string = process.env.REACT_APP_WIDGET_PATH || "";
export const API_VERSION = "v1";
export const APP_ENV = process.env.REACT_APP_ENV || "development";
